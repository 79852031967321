.btn_profile {
    background-color: #006BDC;
    text-transform: uppercase;
    color: #fff;
    border-radius: 50px;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    padding: 13px 23px;
    transition: all 0.5s;
    display: inline-block;
    box-shadow: 0px 12px 19px -5px rgb(0 107 220 / 68%);
    letter-spacing: 1px;
    overflow: hidden;
    z-index: 1;
    border: none;
}

.profile-btn-container button {}

.profile-btn-container {
    align-items: center;
    display: flex;
    flex-direction: column;
}