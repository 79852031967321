.btn__submit {
  background-color: #006BDC;
  text-transform: uppercase;
  color: #fff;
  border-radius: 50px;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 13px 23px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  display: inline-block;
  -webkit-box-shadow: 0px 12px 19px -5px rgba(0, 107, 220, 0.68);
          box-shadow: 0px 12px 19px -5px rgba(0, 107, 220, 0.68);
  letter-spacing: 1px;
  overflow: hidden;
  z-index: 1;
  border: none;
}
@media (max-width: 1400px) {
  .btn__submit {
    padding: 12px 20px;
  }
}
@media (max-width: 1199px) {
  .btn__submit {
    font-size: 14px;
    padding: 10px 18px;
  }
}
.btn__submit:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 25px;
  right: 0px;
  bottom: 0px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
  background: #4D4D4D;
  width: 124px;
  border-radius: 30px;
  height: 50px;
}
.btn__submit:hover {
  color: #fff;
}
.btn__submit:hover:after {
  -webkit-transform: scale(2);
          transform: scale(2);
}
.btn__submit--load-more {
  text-align: center;
  padding: 9px 20px;
  width: 137px;
  margin: 0 auto 55px;
  -webkit-box-shadow: none;
          box-shadow: none;
  display: block;
  z-index: 1;
}
.btn__submit--load-more:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 25px;
  right: 0px;
  bottom: 0px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
  background: #4D4D4D;
  width: 127px;
  border-radius: 30px;
  height: 50px;
}
.btn__submit--load-more:hover {
  color: #fff;
}
.btn__submit--load-more:hover:after {
  -webkit-transform: scale(2);
          transform: scale(2);
}
@media (max-width: 767px) {
  .btn__submit--load-more {
    font-size: 13px;
    margin: 0 auto 25px;
  }
}
.btn__submit--upload-more {
  text-align: center;
  padding: 10px 20px;
  width: 223px;
  margin: 0 auto;
  display: block;
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 1;
}
.btn__submit--upload-more:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0px;
  left: 25px;
  right: 0px;
  bottom: 0px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
  background: #4D4D4D;
  width: 133px;
  border-radius: 30px;
  height: 50px;
}
.btn__submit--upload-more:hover {
  color: #fff;
}
.btn__submit--upload-more:hover:after {
  -webkit-transform: scale(2);
          transform: scale(2);
}

.btn__link {
  background-color: #006BDC;
  text-transform: uppercase;
  color: #fff;
  border-radius: 50px;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 25px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  display: inline-block;
  -webkit-box-shadow: none;
          box-shadow: none;
  letter-spacing: 1px;
  width: 155px;
}
@media (max-width: 600px) {
  .btn__link {
    width: 145px;
    font-size: 12px;
    padding: 10px 20px;
  }
}
.btn__link:after {
  content: "";
  display: inline-block;
  width: 38px;
  height: 10px;
  background: url(../images/icons/forward-arrow.svg) 0 0 no-repeat;
  background-size: contain;
  position: absolute;
  right: 18px;
  top: 16px;
}
.btn__link--read-more {
  font-size: 11px;
  background-color: #006BDC;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 146px;
  padding: 9px 20px;
}
.btn__link--read-more:after {
  content: "";
  display: inline-block;
  width: 38px;
  height: 10px;
  background: url(../images/icons/forward-arrow.svg) 0 0 no-repeat;
  background-size: contain;
  position: absolute;
  right: 12px;
  top: 12px;
}
.btn__link--view-more {
  background-color: #FFB71B;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 180px;
  padding: 10px 25px;
}
@media (max-width: 600px) {
  .btn__link--view-more {
    width: 160px;
    padding: 10px 20px;
  }
}
.btn__link--view-more:after {
  content: "";
  display: block;
  width: 38px;
  height: 10px;
  background: url(../images/icons/forward-arrow.svg) 0 0 no-repeat;
  background-size: contain;
  position: absolute;
  right: 26px;
  top: 16px;
}
.btn__link--get-in-touch {
  background-color: #D60812;
  display: block;
  font-weight: 600;
  padding: 10px 25px;
  width: 197px;
  margin: 0 10px 0 0;
  letter-spacing: 1px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1600px) {
  .btn__link--get-in-touch {
    margin: 0 100px 0 0;
  }
}
@media (max-width: 1300px) {
  .btn__link--get-in-touch {
    margin: 0 175px 0 0;
  }
}
@media (max-width: 992px) {
  .btn__link--get-in-touch {
    padding: 10px 18px;
    width: 165px;
    font-size: 12px;
    margin: 25px 0 0 0;
  }
}
.btn__link--get-in-touch:after {
  content: "";
  display: block;
  width: 38px;
  height: 10px;
  background: url(../images/icons/forward-arrow.svg) 0 0 no-repeat;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 15px;
}
@media (max-width: 767px) {
  .btn__link--get-in-touch:after {
    right: 9px;
  }
}
@media (max-width: 575px) {
  .btn__link--get-in-touch:after {
    right: 13px;
  }
}
.btn__link:hover {
  color: #fff;
  background-color: #4D4D4D;
}
.btn__link:hover:after {
  -webkit-animation: shake 0.7s;
          animation: shake 0.7s;
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  80% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  50% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  80% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.popup__btn {
  background: #2A7DE1;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
}
.popup__btn:hover {
  background-color: #4D4D4D;
  color: #fff;
}
.popup__btn--upload {
  color: #00000085;
  padding: 12px 35px 12px 18px;
  font-weight: 900;
  background-color: #FCFCFC;
  border: 2px solid #DCDCDC;
  font-size: 14px;
}
.popup__btn--upload i {
  font-size: 19px;
  color: #000;
}
.popup__btn--upload:hover {
  background-color: transparent;
  color: #00000085;
}
.popup__btn.scrollTop {
  background-color: #D2E5FD;
  padding: 12px 17px;
  position: absolute;
  right: 50px;
  bottom: 45px;
}
.popup__btn.scrollTop i {
  color: #2A2A30;
}
.popup__btn.scrollTop:hover {
  background-color: #4D4D4D;
}
.popup__btn.scrollTop:hover img {
  -webkit-filter: invert(100%);
          filter: invert(100%);
  -webkit-animation: bounce2 500ms ease infinite;
          animation: bounce2 500ms ease infinite;
}
@-webkit-keyframes bounce2 {
  80% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes bounce2 {
  80% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.popup__btn.scrollTop--red {
  background: #FDD0D2;
  bottom: 50px;
}
@media (max-width: 768px) {
  .popup__btn.scrollTop {
    right: 25px;
  }
}
@media (max-width: 992px) {
  .popup__btn {
    font-size: 12px;
    padding: 8px 20px;
  }
}

html {
  scroll-behavior: smooth;
}

ol,
ul,
li,
figure {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: transparent;
}
a:hover {
  text-decoration: none;
}

body {
  font-family: "Manrope", sans-serif;
}

.page__holer {
  max-width: 100%;
  overflow: hidden;
}

.container {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

.heading__main {
  position: relative;
  font-size: 45px;
  line-height: 50px;
  font-weight: 800;
  color: #4D4D4D;
}
@media (max-width: 1199px) {
  .heading__main {
    font-size: 40px;
    line-height: 45px;
  }
}
@media (max-width: 600px) {
  .heading__main {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 425px) {
  .heading__main {
    font-size: 20px;
    line-height: 25px;
  }
}
.heading__main.small-font {
  font-size: 40px;
}
@media (max-width: 425px) {
  .heading__main.small-font {
    font-size: 25px;
  }
}
.heading__main.small-size {
  font-size: 35px;
}
@media (max-width: 992px) {
  .heading__main.small-size {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 575px) {
  .heading__main.small-size {
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .heading__main.small-size {
    font-size: 20px;
    line-height: 25px;
  }
}
.heading__main:after {
  content: "";
  background-color: #D60812;
  position: absolute;
  width: 39px;
  height: 7px;
  bottom: -25px;
  z-index: 1;
  border-radius: 10px;
  left: 0;
}
.heading__main--reddish:after {
  background-color: #D60812;
  content: "";
  position: absolute;
  width: 39px;
  height: 7px;
  bottom: -26px;
  z-index: 1;
  border-radius: 10px;
  left: 0;
}
.heading__main--blueish:after {
  background-color: #006BDC;
  content: "";
  position: absolute;
  width: 39px;
  height: 7px;
  bottom: -25px;
  z-index: 1;
  border-radius: 10px;
  left: 0;
}
.heading__main--light-color {
  color: #fff;
}
.heading__main--light-color:after {
  background-color: #FFB71B;
  content: "";
  position: absolute;
  width: 39px;
  height: 7px;
  bottom: -25px;
  z-index: 1;
  border-radius: 10px;
  left: 0;
}
.heading__main--get-in-touch:after {
  background-color: #FFB71B;
  content: "";
  position: absolute;
  width: 39px;
  height: 7px;
  bottom: -25px;
  z-index: 1;
  border-radius: 10px;
  left: 0;
}
@media (max-width: 991px) {
  .heading {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .heading {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .heading {
    padding: 0 0 10px 5px;
  }
}

.section__title {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  margin-bottom: 10px;
}
@media (max-width: 425px) {
  .section__title {
    font-size: 13px;
  }
}
.section__title--primary__colored {
  color: #006BDC;
}
.section__title--yellow__colored {
  color: #FFB71B;
}
@media (max-width: 425px) {
  .section__title--yellow__colored {
    font-size: 16px;
  }
}
.section__title--reddish {
  color: #D60812;
}
.section__title--dark__color {
  color: #D60812;
}

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
  background-color: white;
  z-index: 10;
  left: 0;
  top: 0px;
}
.header.shadow__color {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
}
.header .nav__wrapper {
  padding: 30px 0px;
}
@media (max-width: 600px) {
  .header .nav__wrapper {
    padding: 20px 0px;
  }
}
.header .header__logo__holder {
  width: 273px;
}
@media (max-width: 992px) {
  .header .header__logo__holder {
    padding-top: 0px;
  }
}
@media (max-width: 600px) {
  .header .header__logo__holder {
    width: 220px;
  }
}
@media (max-width: 992px) {
  .menu-open .header__nav__holder .nav__responsive {
    display: block;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 45px 0px;
    background-color: rgba(255, 255, 255, 0.45);
    -webkit-backdrop-filter: blur(13.5px);
            backdrop-filter: blur(13.5px);
  }
  .menu-open .header__nav__holder .nav__responsive .header__nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 40px 0px;
  }
  .menu-open .header__nav__holder .nav__responsive .header__nav ul li a {
    font-size: 20px;
    padding: 5px 0px;
  }
}
.menu-open .header__nav__holder .hamburger .bar1 {
  -webkit-transform: rotate(45deg) translate(6px, 3px);
          transform: rotate(45deg) translate(6px, 3px);
}
.menu-open .header__nav__holder .hamburger .bar2 {
  -webkit-transform: rotate(313deg) translate(-2px, -3px);
          transform: rotate(313deg) translate(-2px, -3px);
}
.menu-open .header__nav__holder .hamburger .bar3 {
  opacity: 0;
}
.header__nav__holder .nav__responsive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 992px) {
  .header__nav__holder .nav__responsive {
    position: absolute;
    right: -20px;
    top: 100%;
    background: #fff;
    padding: 10px;
    display: none;
    -webkit-box-shadow: 5px 3px 16px rgba(0, 0, 0, 0.16);
            box-shadow: 5px 3px 16px rgba(0, 0, 0, 0.16);
    min-height: 500px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    display: block;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 45px 0px;
  }
}
.header__nav__holder .header__nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 992px) {
  .header__nav__holder .header__nav ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 40px 0px;
  }
}
.header__nav__holder .header__nav ul li a {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #4d4d4d;
  margin: 0px 33px 0px 0px;
  position: relative;
  z-index: 0;
  text-decoration: none;
  letter-spacing: 1px;
}
@media (max-width: 1400px) {
  .header__nav__holder .header__nav ul li a {
    margin: 0px 27px 0px 0px;
  }
}
@media (max-width: 1199px) {
  .header__nav__holder .header__nav ul li a {
    font-size: 13px;
    margin: 0px 20px 0px 0px;
  }
}
.header__nav__holder .header__nav ul li a:hover {
  color: #006BDC;
}
.header__nav__holder .header__nav ul li a:hover:after {
  width: 100%;
}
.header__nav__holder .header__nav ul li a:after {
  position: absolute;
  right: 0px;
  left: 0;
  bottom: 0;
  display: inline-block;
  content: "";
  width: 0;
  height: 1px;
  background-color: #006BDC;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.hamburger {
  cursor: pointer;
  margin: 0 0 0 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: none;
  background-color: #006BDC;
  position: relative;
  z-index: 1;
}
@media (max-width: 992px) {
  .hamburger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0 5px;
  }
}
.hamburger .bar1,
.hamburger .bar5 {
  height: 2px;
  border-radius: 50px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: block;
  width: 25px;
  position: absolute;
  top: 15px;
  height: 2px;
}
.hamburger .bar2,
.hamburger .bar4 {
  height: 2px;
  border-radius: 50px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: block;
  width: 25px;
  position: absolute;
  top: 22px;
  right: 5px;
}
.hamburger .bar3 {
  height: 2px;
  border-radius: 50px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: block;
  width: 25px;
  position: absolute;
  bottom: 14px;
}
.hamburger--square {
  width: 25px;
  height: 25px;
  display: block;
  background: #2A7DE1;
  opacity: 1;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .hamburger--square {
    width: 15px;
    height: 15px;
  }
}
.hamburger--square .bar1 {
  width: 24px;
  top: 21px;
  height: 2px;
  right: 15px;
  -webkit-transform: rotate(-45deg) translate(6px, 3px);
          transform: rotate(-45deg) translate(6px, 3px);
  -webkit-transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
  transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
}
@media (max-width: 768px) {
  .hamburger--square .bar1 {
    width: 17px;
    top: 17px;
    height: 2px;
    right: 13px;
  }
}
.hamburger--square .bar2 {
  width: 24px;
  top: 23px;
  height: 2px;
  right: 9px;
  -webkit-transform: rotate(45deg) translate(-2px, -3px);
          transform: rotate(45deg) translate(-2px, -3px);
  -webkit-transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
  transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
}
@media (max-width: 768px) {
  .hamburger--square .bar2 {
    width: 17px;
    top: 18px;
    height: 2px;
    right: 7px;
  }
}
.hamburger--square--red {
  background-color: #D60812;
}
.hamburger--square--red:hover .bar1, .hamburger--square--red:hover .bar2 {
  background: #D60812 !important;
}
.hamburger--square:hover {
  background: #fff;
}
.hamburger--square:hover .bar1 {
  background: #2A7DE1;
  top: 20px;
  right: 8px;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  -webkit-transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
  transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
}
.hamburger--square:hover .bar2 {
  background: #2A7DE1;
  top: 20px;
  right: 8px;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  -webkit-transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
  transition: 0.5s cubic-bezier(0.62, 0.02, 0.34, 1);
}

.top__bottom__border {
  height: 4px;
  background: #006BDC;
  background-image: -webkit-gradient(linear, left top, right top, from(#006BDC), color-stop(17%, #006BDC), color-stop(17%, #D60812), color-stop(33%, #D60812), color-stop(33%, #006BDC), color-stop(49%, #006BDC), color-stop(49%, #D60812), color-stop(65%, #D60812), color-stop(65%, #006BDC), color-stop(80%, #006BDC), color-stop(80%, #D60812), to(#D60812));
  background-image: linear-gradient(to right, #006BDC 0%, #006BDC 17%, #D60812 17%, #D60812 33%, #006BDC 33%, #006BDC 49%, #D60812 49%, #D60812 65%, #006BDC 65%, #006BDC 80%, #D60812 80%, #D60812 100%);
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay--after-open .modal-contents {
  -webkit-animation: fade-up 1s ease;
          animation: fade-up 1s ease;
}
@-webkit-keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes fade-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.ReactModal__Overlay--before-close .modal-contents {
  -webkit-animation: fade-out 1s ease;
          animation: fade-out 1s ease;
}
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
}
.ReactModal__Overlay--before-close.modal-overlay {
  -webkit-animation: fade-out1 500ms linear;
          animation: fade-out1 500ms linear;
}
@-webkit-keyframes fade-out1 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out1 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.modal-holder {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  height: 100%;
  outline: 0;
}
.modal-holder .modal-contents {
  max-width: 1100px;
  height: 92vh;
  margin: 40px auto 0;
  position: relative;
  background: #F9F9F9;
  border-radius: 30px;
  padding: 20px;
  border: 2px solid #fff;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 15px 6px rgba(42, 125, 225, 0.39);
          box-shadow: 0px 0px 15px 6px rgba(42, 125, 225, 0.39);
}
@media screen and (max-width: 1300px) and (min-width: 992px) {
  .modal-holder .modal-contents {
    max-width: 1000px;
  }
}
@media screen and (max-width: 992px) {
  .modal-holder .modal-contents {
    max-width: 720px;
  }
}
@media (max-width: 768px) {
  .modal-holder .modal-contents {
    padding: 15px;
  }
}
.modal-holder .modal-contents .modal-header {
  border: none;
  padding: 30px 50px;
}
.modal-holder .modal-contents .modal-header.add-shadow {
  -webkit-box-shadow: 0px 6px 13px -10px rgba(42, 125, 225, 0.39);
          box-shadow: 0px 6px 13px -10px rgba(42, 125, 225, 0.39);
}
@media (max-width: 768px) {
  .modal-holder .modal-contents .modal-header {
    padding: 30px 20px;
  }
}
.modal-holder .modal-contents .modal-header .logo__holder {
  width: 225px;
}
@media (max-width: 1199px) {
  .modal-holder .modal-contents .modal-header .logo__holder {
    width: 250px;
  }
}
@media (max-width: 992px) {
  .modal-holder .modal-contents .modal-header .logo__holder {
    width: 210px;
  }
}
@media (max-width: 768px) {
  .modal-holder .modal-contents .modal-header .logo__holder {
    width: 150px;
  }
}
.modal-holder .modal-contents .modal-header .btn-close {
  margin: 0;
}
.modal-holder .modal-contents .form__label {
  font-size: 14px;
  font-weight: 900;
  color: #111111;
  margin-bottom: 10px;
}
.modal-holder .modal-contents .form__label span {
  color: #FB6C64;
  padding: 0px 0 0 2px;
  font-size: 15px;
}
.modal-holder .modal-contents .form input,
.modal-holder .modal-contents .form textarea {
  border: 2px solid #DCDCDC;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 14px;
}
.modal-holder .modal-contents .form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #00000085;
  font-weight: 900;
}
.modal-holder .modal-contents .form ::-webkit-input-placeholder {
  color: #00000085;
  font-weight: 900;
}
.modal-holder .modal-contents .form :-ms-input-placeholder {
  color: #00000085;
  font-weight: 900;
}
.modal-holder .modal-contents .form ::-ms-input-placeholder {
  color: #00000085;
  font-weight: 900;
}
.modal-holder .modal-contents .form ::placeholder {
  color: #00000085;
  font-weight: 900;
}
.modal-holder .modal-contents .form .policy {
  font-size: 12px;
  color: #000;
  margin-bottom: 35px;
}
.modal-holder .modal-contents .form .policy a {
  color: #2A7DE1;
  text-decoration: underline;
}
.modal-holder .modal-contents .form .validation-error {
  color: #FB6C64;
  font-size: 13px;
  margin: 5px 0 0;
  display: inline-block;
}
.modal-holder .modal-contents .recaptcha-box {
  margin-bottom: 30px;
}
.modal-holder .modal-contents .modal-wrapper {
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  background-color: #F7F9FC;
  border: 1px solid #2a7de14d;
  height: 100%;
}
.modal-holder .modal-contents .padding-box {
  max-height: 85%;
  overflow: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modal-holder .modal-contents .padding-box#scroll-job::-webkit-scrollbar {
  width: 3px;
}
.modal-holder .modal-contents .padding-box#scroll-job::-webkit-scrollbar-track {
  background: #F7F9FC;
}
.modal-holder .modal-contents .padding-box#scroll-job::-webkit-scrollbar-thumb {
  background-color: #4d4d4d87;
  border-radius: 20px;
}
.modal-holder .modal-contents .padding-box .scroll-content {
  padding: 25px 50px 40px;
}
@media (max-width: 768px) {
  .modal-holder .modal-contents .padding-box .scroll-content {
    padding: 0px 20px 20px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.45);
  -webkit-backdrop-filter: blur(13.5px);
          backdrop-filter: blur(13.5px);
}
.modal-overlay:before {
  content: "";
  position: absolute;
  background: url(../images/bg/red-circle.png) no-repeat;
  width: 154px;
  height: 154px;
  right: -60px;
  top: 57px;
  -webkit-animation: rotate-in-up-left 3s ease;
          animation: rotate-in-up-left 3s ease;
}
@-webkit-keyframes rotate-in-up-left {
  0% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-up-left {
  0% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .modal-overlay:before {
    display: none;
  }
}
.modal-overlay:after {
  content: "";
  position: absolute;
  background: url(../images/bg/red-circle.png) no-repeat;
  width: 154px;
  height: 154px;
  left: -80px;
  bottom: 60px;
  -webkit-animation: rotate-in-down-left 3s ease;
          animation: rotate-in-down-left 3s ease;
}
@-webkit-keyframes rotate-in-down-left {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-down-left {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .modal-overlay:after {
    display: none;
  }
}
.modal-overlay--two:before {
  content: "";
  position: absolute;
  background: url(../images/bg/bg-blue-circle.png) no-repeat;
  width: 154px;
  height: 154px;
  right: -60px;
  top: 45px;
  z-index: -1;
}
.modal-overlay--two:after {
  content: "";
  position: absolute;
  background: url(../images/bg/green-circle.png) no-repeat;
  width: 154px;
  height: 154px;
  left: -74px;
  z-index: -1;
}

.modal-list {
  padding: 10px 10px 0px 60px;
}
@media (max-width: 992px) {
  .modal-list {
    padding: 10px 10px 0px 50px;
  }
}
@media (max-width: 992px) {
  .modal-list {
    padding: 0px 0px 0px 45px;
  }
}
.modal-list li {
  color: #4D4D4D;
  font-size: 18px;
  padding-bottom: 15px;
  position: relative;
  line-height: 33px;
}
@media (max-width: 992px) {
  .modal-list li {
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 10px;
    text-align: justify;
  }
}
.modal-list li:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #4D4D4D;
  position: absolute;
  left: -34px;
  top: 11px;
}
@media (max-width: 992px) {
  .modal-list li:before {
    width: 10px;
    height: 10px;
    left: -31px;
    top: 8px;
  }
}

.popup__explore__content {
  margin: 0 0 80px;
}
.popup__explore__content h4 {
  font-size: 22px;
  font-weight: 900;
  color: #D60812;
  margin-bottom: 30px;
}
@media (max-width: 992px) {
  .popup__explore__content h4 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
.popup__explore__content p {
  font-size: 18px;
  line-height: 1.6;
  color: #4D4D4D;
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .popup__explore__content p {
    font-size: 17px;
    line-height: 28px;
    text-align: justify;
    margin-bottom: 10px;
  }
}
.popup__explore .video__holder {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 50px;
}
.popup__explore .video__holder a.top-right {
  position: absolute;
  right: 0;
  top: 0;
  border-top: 110px solid #F8A4A8;
  border-left: 124px solid transparent;
}
@media (max-width: 600px) {
  .popup__explore .video__holder a.top-right {
    border-top: 60px solid #F8A4A8;
    border-left: 65px solid transparent;
  }
}
.popup__explore .video__holder a.top-right:after {
  content: "";
  position: absolute;
  top: -95px;
  background: url(../images/icons/speaker.svg) no-repeat;
  width: 38px;
  height: 38px;
  right: 15px;
}
@media (max-width: 600px) {
  .popup__explore .video__holder a.top-right:after {
    top: -50px;
    width: 18px;
    height: 18px;
    right: 9px;
    background-size: cover;
  }
}
.popup__explore .video__holder a.top-right--unmute:after {
  content: "";
  position: absolute;
  top: -95px;
  background: url(../images/icons/unmute.svg) no-repeat;
  width: 24px;
  height: 34px;
  right: 25px;
}
@media (max-width: 600px) {
  .popup__explore .video__holder a.top-right--unmute:after {
    top: -50px;
    width: 14px;
    height: 18px;
    right: 11px;
    background-size: cover;
  }
}
.popup__explore .video__holder a.bottom-left {
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 110px solid transparent;
  border-left: 124px solid #F8A4A8;
  z-index: 1;
}
@media (max-width: 600px) {
  .popup__explore .video__holder a.bottom-left {
    border-top: 60px solid transparent;
    border-left: 65px solid #F8A4A8;
  }
}
.popup__explore .video__holder a.bottom-left:after {
  content: "";
  position: absolute;
  top: -50px;
  background: url(../images/icons/play-btn.svg) no-repeat;
  width: 38px;
  height: 38px;
  right: 70px;
}
@media (max-width: 600px) {
  .popup__explore .video__holder a.bottom-left:after {
    top: -26px;
    width: 18px;
    height: 18px;
    right: 36px;
    background-size: cover;
  }
}
.popup__explore .video__holder a.bottom-left--pause:after {
  content: "";
  position: absolute;
  top: -50px;
  background: url(../images/icons/pause.svg) no-repeat;
  width: 38px;
  height: 38px;
  right: 70px;
}
@media (max-width: 600px) {
  .popup__explore .video__holder a.bottom-left--pause:after {
    top: -26px;
    width: 18px;
    height: 18px;
    right: 36px;
    background-size: cover;
  }
}
.popup__explore .video__holder figure {
  height: 525px;
}
@media (max-width: 992px) {
  .popup__explore .video__holder figure {
    height: 300px;
  }
}
@media (max-width: 575px) {
  .popup__explore .video__holder figure {
    height: 200px;
  }
}
.popup__explore .video__holder figure video {
  -o-object-fit: cover;
     object-fit: cover;
}
.popup__explore .video__holder:before {
  content: "";
  position: absolute;
  top: -122px;
  right: -76px;
  width: 185px;
  height: 265px;
  background-color: #f7f9fc;
  -webkit-transform: rotate(133deg);
          transform: rotate(133deg);
}
@media (max-width: 600px) {
  .popup__explore .video__holder:before {
    width: 120px;
  }
}
.popup__explore .video__holder:after {
  content: "";
  position: absolute;
  bottom: -122px;
  left: -76px;
  width: 185px;
  height: 265px;
  background-color: #f7f9fc;
  -webkit-transform: rotate(133deg);
          transform: rotate(133deg);
}
@media (max-width: 600px) {
  .popup__explore .video__holder:after {
    width: 120px;
  }
}

.popup__job .main__heading {
  color: #2A7DE1;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .popup__job .main__heading {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .popup__job .main__heading {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.popup__job__about p {
  color: #4D4D4D;
  font-size: 18px;
  line-height: 34px;
}
@media (max-width: 1199px) {
  .popup__job__about p {
    font-size: 16px;
    line-height: 32px;
  }
}
@media (max-width: 992px) {
  .popup__job__about p {
    font-size: 14px;
    line-height: 28px;
    text-align: justify;
  }
}
.popup__job .mb-6 {
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  .popup__job .mb-6 {
    margin-bottom: 50px;
  }
}
@media (max-width: 992px) {
  .popup__job .mb-6 {
    margin-bottom: 40px;
  }
}
@media (max-width: 768px) {
  .popup__job .mb-6 {
    margin-bottom: 20px;
  }
}
.popup__job h3 {
  color: #2A2A30;
  font-size: 35px;
  font-weight: 900;
  margin-bottom: 0px;
}
@media (max-width: 1199px) {
  .popup__job h3 {
    font-size: 33px;
  }
}
@media (max-width: 992px) {
  .popup__job h3 {
    font-size: 31px;
  }
}
@media (max-width: 768px) {
  .popup__job h3 {
    font-size: 19px;
  }
}
.popup__job .form__job .custom__check {
  margin: 0 40px 0px 0;
}
@media (max-width: 992px) {
  .popup__job .form__job .custom__check {
    margin: 0 10px 0 0;
  }
}
@media (max-width: 370px) {
  .popup__job .form__job .custom__check {
    display: block;
  }
}
.popup__job .form__job .custom__check .add__margin {
  margin: 0 25px;
  padding: 9px;
  vertical-align: sub;
}
@media (max-width: 768px) {
  .popup__job .form__job .custom__check .add__margin {
    margin: 10px 20px 0px 0px;
  }
}
.popup__job .form__job .custom__check .add__margin--one {
  margin: 0 25px 0 61px;
}
@media (max-width: 992px) {
  .popup__job .form__job .custom__check .add__margin--one {
    margin: 0 25px 0 50px;
  }
}
@media (max-width: 768px) {
  .popup__job .form__job .custom__check .add__margin--one {
    margin: 10px 24px 0 0px;
    display: inline-block;
  }
}
.popup__job .form__job .custom__check .add__margin--two {
  margin: 0 24px 0 54px;
}
@media (max-width: 992px) {
  .popup__job .form__job .custom__check .add__margin--two {
    margin: 0 20px 0 49px;
  }
}
@media (max-width: 768px) {
  .popup__job .form__job .custom__check .add__margin--two {
    margin: 0 23px 0 18px;
  }
}
@media (max-width: 365px) {
  .popup__job .form__job .custom__check .add__margin--two {
    margin: 10px 26px 0 0px;
  }
}
.popup__job .form__job .custom__check .add__margin--three {
  margin: 0 25px 0 49px;
}
@media (max-width: 992px) {
  .popup__job .form__job .custom__check .add__margin--three {
    margin: 0px 25px 0 15px;
  }
}
@media (max-width: 455px) {
  .popup__job .form__job .custom__check .add__margin--three {
    margin: 10px 25px 0 0px;
  }
}
.popup__job .job__location {
  font-size: 20px;
  color: #2A2A30;
  font-weight: 400;
  position: relative;
}
@media (max-width: 1199px) {
  .popup__job .job__location {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .popup__job .job__location {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .popup__job .job__location {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
.popup__job .job__location__value {
  color: #2A2A30;
  font-weight: 900;
  font-size: 20px;
  position: relative;
}
@media (max-width: 1199px) {
  .popup__job .job__location__value {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .popup__job .job__location__value {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .popup__job .job__location__value {
    font-size: 14px;
  }
}
@media (max-width: 385px) {
  .popup__job .job__location__value.mobile-view {
    margin: 8px 0 0 25px;
    display: inline-block;
  }
}
.popup__job .job__location.add-border {
  border-left: 1px solid;
  border-right: 1px solid;
}
@media (max-width: 768px) {
  .popup__job .job__location.add-border {
    border: none;
  }
}
.popup__job__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 25px;
  margin-bottom: 15px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.popup__job__title button {
  color: #2A2A30;
  background-color: #fff;
  border: 2px solid #DBDBDB;
  font-size: 15px;
  padding: 0px 11px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
}
@media (max-width: 768px) {
  .popup__job__title button {
    font-size: 13px;
    padding: 0px 8px;
    height: 30px;
    width: 30px;
  }
}
.popup__job__title .share-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.popup__job__title .share-btn span {
  padding-right: 10px;
  font-weight: bold;
  display: none;
  font-size: 15px;
}
.popup__job__title .share-btn span i {
  color: #00AC69;
}
.popup__job__personal span {
  color: #FB6C64;
  padding: 0px 0 0 2px;
  font-size: 15px;
}
.popup__job .form__wrapper small {
  color: #00000099;
  font-size: 16px;
  display: inline-block;
}
@media (max-width: 768px) {
  .popup__job .form__wrapper small {
    font-size: 13px;
  }
}
.popup__job .form__wrapper .mb-45 {
  margin-bottom: 45px;
}
@media (max-width: 768px) {
  .popup__job .form__wrapper .mb-45 {
    margin-bottom: 10px;
  }
}
.popup__job .form__wrapper .form-fill {
  max-width: 680px;
}
.popup__job .form__wrapper .form-fill .cover-letter {
  margin-bottom: 30px;
  max-width: 768px;
}
.popup__job .form__wrapper .form-fill .cover-letter .comments {
  color: #00000085;
  font-weight: 900;
  opacity: 1;
  padding: 22px 0 0 20px;
}
@media (max-width: 768px) {
  .popup__job .form__wrapper .form-fill .cover-letter .comments {
    font-size: 13px;
  }
}
.popup__job .form__wrapper .form-fill .attachment p {
  display: inline-block;
  font-size: 13px;
  color: #0000008f;
}
@media (max-width: 768px) {
  .popup__job .form__wrapper .form-fill .attachment p {
    font-size: 11px;
  }
}
.popup__job .form__wrapper .form-fill .attachment .resume {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #c0c0c03b;
  border-bottom: 1px solid #c0c0c03b;
  padding: 8px 0px;
  margin: 8px 0 0 15px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.popup__job .form__wrapper .form-fill .attachment .resume h5 {
  font-size: 15px;
  margin-bottom: 0;
  color: #000;
}
.popup__job .form__wrapper .form-fill .attachment .resume span {
  font-size: 15px;
  font-weight: 900;
  color: #FB6C64;
}

.popup__Intouch__heading {
  color: #2A2A30;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 900;
  margin: 0 0 30px 0;
  max-width: 810px;
}
@media (max-width: 768px) {
  .popup__Intouch__heading {
    font-size: 20px;
    line-height: 1.5;
  }
}
.popup__Intouch__heading span {
  color: #2A7DE1;
}
.popup__Intouch__sub__heading {
  font-size: 22px;
  font-weight: 900;
  color: #2A2A30;
  margin: 0px 0px 15px;
}
@media (max-width: 768px) {
  .popup__Intouch__sub__heading {
    font-size: 17px;
  }
}
.popup__Intouch__sub__heading--green {
  color: #00AC69;
  margin: 0px 0px 20px;
}
.popup__Intouch__form small {
  font-size: 13px;
  color: #00000099;
  margin-bottom: 30px;
  display: inline-block;
}
.popup__Intouch__enquiry {
  padding: 0 0px 0 20px;
}
@media (max-width: 992px) {
  .popup__Intouch__enquiry {
    padding: 30px 0 0 0;
  }
}
.popup__Intouch__enquiry__tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #fff;
  padding: 40px;
  min-height: 255px;
  margin-bottom: 40px;
  border-bottom: 4px solid #FFB71B;
}
.popup__Intouch__enquiry__tile.speak-to-us h4 {
  color: #2A7DE1;
  font-size: 18px;
  font-weight: 900;
}
.popup__Intouch__enquiry__tile.speak-to-us p {
  color: #2A2A30;
  font-size: 14px;
  line-height: 1.5;
  margin: 5px 0px 20px 0px;
}
.popup__Intouch__enquiry__tile.speak-to-us span {
  color: #2A7DE1;
  font-size: 15px;
  font-weight: 900;
}
.popup__Intouch__enquiry__tile.address-tile h4 {
  color: #00AC69;
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 20px;
}
.popup__Intouch__enquiry__tile.address-tile p {
  color: #000;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.6;
  margin-bottom: 0;
}
.popup__Intouch__form .form-details {
  margin-bottom: 30px;
}
.popup__Intouch__form .form-details.small-size {
  width: 50%;
}
@media (max-width: 992px) {
  .popup__Intouch__form .form-details.small-size {
    width: 100%;
  }
}
.popup__Intouch__form .form-details span {
  color: #FB6C64;
  padding: 0px 0 0 2px;
  font-size: 15px;
  margin: 0;
  display: inline-block;
}
.popup__Intouch__form .message {
  margin-bottom: 40px;
}

.popup__technology {
  overflow: hidden;
}
.popup__technology__content {
  padding: 25px 50px 45px;
}
@media (max-width: 768px) {
  .popup__technology__content {
    padding: 25px 20px;
  }
}
.popup__technology__content h3 {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}
@media (max-width: 992px) {
  .popup__technology__content h3 {
    font-size: 22px;
  }
}
.popup__technology__content h4 {
  color: #2A7DE1;
  font-size: 22px;
  font-weight: 900;
  margin: 20px 0;
}
@media (max-width: 992px) {
  .popup__technology__content h4 {
    font-size: 19px;
  }
}
.popup__technology__content h5 {
  color: #000;
  font-size: 25px;
  font-weight: 900;
}
@media (max-width: 992px) {
  .popup__technology__content h5 {
    font-size: 22px;
  }
}
.popup__technology__content p {
  color: #4D4D4D;
  font-size: 17px;
  line-height: 1.6;
  max-width: 390px;
  margin: 30px 0px;
  min-height: 270px;
}
@media (max-width: 992px) {
  .popup__technology__content p {
    font-size: 16px;
    margin: 20px 0px;
  }
}
@media (max-width: 768px) {
  .popup__technology__content p {
    text-align: justify;
  }
}
.popup__technology__content__icon {
  margin: 55px 0 60px 0;
  position: relative;
}
@media (max-width: 992px) {
  .popup__technology__content__icon {
    margin: 65px 0 60px 0;
  }
}
.popup__technology__content__icon img {
  width: 90px;
}
.popup__technology__content__img {
  position: absolute;
  width: 750px;
  height: 945px;
  right: -412px;
  border-radius: 30px;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 130px;
}
.popup__technology__content__img.bg-red {
  background-color: rgba(42, 125, 225, 0.05);
}
@media (max-width: 992px) {
  .popup__technology__content__img {
    width: 540px;
    height: 600px;
    right: -412px;
    top: 403px;
  }
}
@media (max-width: 768px) {
  .popup__technology__content__img {
    display: none;
  }
}
.popup__technology__content__img img {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  margin-left: -330px;
  margin-top: 72px;
  min-width: 790px;
  max-height: 785px;
}
@media (max-width: 992px) {
  .popup__technology__content__img img {
    margin-left: -210px;
    margin-top: 100px;
    min-width: 500px;
    max-height: 440px;
  }
}
.popup__technology__content:after {
  content: "";
  position: absolute;
  right: -50px;
  z-index: 9999;
  width: 0;
  height: 0;
  border-top: 200px solid transparent;
  border-right: 200px solid #F7F9FC;
  border-bottom: 190px solid transparent;
  bottom: -95px;
}
@media (max-width: 992px) {
  .popup__technology__content:after {
    border-top: 125px solid transparent;
    border-right: 130px solid #F7F9FC;
    border-bottom: 140px solid transparent;
    bottom: -95px;
  }
}

.line {
  background: #FFB71B;
  width: 80%;
  height: 2px;
  margin: 80px auto;
}
@media (max-width: 768px) {
  .line {
    margin: 50px auto;
  }
}

.footer {
  padding: 40px 0px;
  background-color: #F7F9FC;
}
@media (max-width: 991px) {
  .footer {
    padding: 35px 0;
  }
}
.footer__nav__menu .footer__logo {
  display: inline-block;
  width: 273px;
}
@media (max-width: 600px) {
  .footer__nav__menu .footer__logo {
    width: 220px;
  }
}
.footer__nav__menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px 0px 5px 0;
}
@media (max-width: 666px) {
  .footer__nav__menu ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.footer__nav__menu ul li a {
  font-size: 15px;
  font-weight: 700;
  color: #4d4d4d;
  text-transform: uppercase;
  z-index: 0;
  cursor: pointer;
  margin: 0 18px 0 0;
  position: relative;
  display: inline-block;
  letter-spacing: 1.5px;
}
@media (max-width: 666px) {
  .footer__nav__menu ul li a {
    font-size: 14px;
    margin: 0 16px 10px 0;
  }
}
.footer__nav__menu ul li a:hover {
  color: #006BDC;
}
.footer__nav__menu ul li a:hover:after {
  width: 100%;
}
.footer__nav__menu ul li a:after {
  position: absolute;
  right: 0px;
  bottom: 0;
  left: 0;
  display: inline-block;
  content: "";
  width: 0;
  height: 1px;
  background-color: #006BDC;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}
.footer__social__media h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  color: #4D4D4D;
  font-size: 15px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font-weight: 600;
  margin-bottom: 0;
  margin: 40px 0px 0 0;
}
@media (max-width: 991px) {
  .footer__social__media h3 {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 15px 0 20px 0;
    font-size: 14px;
  }
}
.footer__social__media a {
  margin: 0 20px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.footer__social__media a i {
  color: #7a7a7a;
  font-size: 20px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.footer__social__media a:hover i {
  color: #006BDC;
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
}
.footer__social__media p {
  font-size: 16px;
  color: #575f6b;
  margin-bottom: 20px;
}
.footer__social__media p a {
  color: #d60812;
  text-decoration: underline;
}
.footer__social__media p a:hover {
  color: #006BDC;
}
.footer__copy__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 652px) {
  .footer__copy__right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.footer__copy__right p {
  font-size: 18px;
  color: #241e1e;
  letter-spacing: 2.4px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 652px) {
  .footer__copy__right p {
    font-size: 15px;
    letter-spacing: 2.1px;
    line-height: 27px;
  }
}
.footer__copy__right p span {
  font-size: 38px;
  color: #D60812;
  padding: 0 10px;
  font-weight: 100;
}
@media (max-width: 652px) {
  .footer__copy__right p span {
    font-size: 35px;
    padding: 0 10px 0 0;
  }
}
@media (max-width: 652px) {
  .footer__copy__right .company {
    margin-bottom: 8px;
  }
}

.parallax {
  width: 100%;
  height: 100vh;
  position: absolute;
  pointer-events: all !important;
}
.parallax#scene {
  height: 76vh;
}
.parallax .shape img {
  position: relative;
}
.parallax .shape:nth-child(1) img {
  top: calc(50vh - 215px);
  left: calc(30vw - 180px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(1) {
    top: 34%;
    right: 26%;
    width: 35px;
  }
}
.parallax .shape:nth-child(2) img {
  top: calc(60vh - 315px);
  left: calc(25vw - 80px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(2) {
    top: 18%;
    right: 24%;
    width: 20px;
  }
}
.parallax .shape:nth-child(3) img {
  top: calc(45vh - 275px);
  left: calc(12vw - 175px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(3) {
    left: 2%;
    top: 25%;
    width: 12px;
  }
}
.parallax .shape:nth-child(4) img {
  top: calc(40vh - 160px);
  left: calc(15vw - 245px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(4) {
    top: 21%;
    left: 17%;
    width: 22px;
  }
}
.parallax .shape:nth-child(5) img {
  top: calc(40vh - 210px);
  left: calc(20vw - 255px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(5) {
    right: 24%;
    top: 23%;
    width: 18px;
  }
}
.parallax .shape:nth-child(6) img {
  top: calc(40vh - 185px);
  left: calc(25vw - 55px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(6) {
    right: 33%;
    top: 22%;
    width: 18px;
  }
}
.parallax .shape:nth-child(7) img {
  top: calc(50vh - 285px);
  left: calc(30vw - 195px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(7) {
    left: 6%;
    top: 34%;
    width: 18px;
  }
}
.parallax .shape:nth-child(8) img {
  top: calc(50vh - 210px);
  left: calc(15vw - 220px);
}
@media (max-width: 425px) {
  .parallax .shape:nth-child(8) {
    left: 6%;
    top: 34%;
    width: 18px;
  }
}
.parallax__works figure img {
  position: relative;
}
.parallax__works figure:nth-child(1) img {
  top: -25px;
  left: 35px;
  -webkit-animation: round1 5s infinite;
          animation: round1 5s infinite;
}
@-webkit-keyframes round1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  75% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes round1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg);
  }
  75% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@media (max-width: 768px) {
  .parallax__works figure:nth-child(1) {
    top: 1%;
    left: 2%;
  }
}
.parallax__works figure:nth-child(2) img {
  top: 400px;
  left: 60px;
  -webkit-animation: rotateYLogo2 2s infinite ease-in-out;
          animation: rotateYLogo2 2s infinite ease-in-out;
}
@-webkit-keyframes rotateYLogo2 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
@keyframes rotateYLogo2 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
@media (max-width: 768px) {
  .parallax__works figure:nth-child(2) {
    top: 25%;
    left: 0%;
  }
}
.parallax__works figure:nth-child(3) img {
  top: 98vh;
  left: 40px;
  -webkit-animation: shake2 5s infinite;
          animation: shake2 5s infinite;
}
@-webkit-keyframes shake2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
@keyframes shake2 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
.parallax__works figure:nth-child(4) img {
  top: 55px;
  left: 90vw;
  -webkit-animation: shake1 5s infinite;
          animation: shake1 5s infinite;
}
@-webkit-keyframes shake1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
@keyframes shake1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
@media (max-width: 768px) {
  .parallax__works figure:nth-child(4) img {
    top: 5%;
    right: 5%;
  }
}
.parallax__works figure:nth-child(5) img {
  top: 170px;
  left: 94vw;
  -webkit-animation: rotateYLogo1 2s infinite ease-in-out;
          animation: rotateYLogo1 2s infinite ease-in-out;
}
@-webkit-keyframes rotateYLogo1 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
@keyframes rotateYLogo1 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
@media (max-width: 768px) {
  .parallax__works figure:nth-child(5) img {
    top: 25%;
    right: 3%;
  }
}
.parallax__works figure:nth-child(6) img {
  top: 95vh;
  left: 95vw;
  -webkit-animation: dot-jump 5s infinite;
          animation: dot-jump 5s infinite;
}
@-webkit-keyframes dot-jump {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  80% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes dot-jump {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  80% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.parallax__careers figure img {
  position: relative;
}
.parallax__careers figure:nth-child(1) img {
  top: -25px;
  left: 140px;
  position: relative;
  -webkit-animation: blinkstar2 3s infinite ease-in-out;
          animation: blinkstar2 3s infinite ease-in-out;
}
@-webkit-keyframes blinkstar2 {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
  50% {
    -webkit-transform: translateZ(150px) rotateY(190deg);
            transform: translateZ(150px) rotateY(190deg);
  }
  80% {
    -webkit-transform: translateZ(0) rotateY(360deg);
            transform: translateZ(0) rotateY(360deg);
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(360deg);
            transform: translateZ(0) rotateY(360deg);
  }
}
@keyframes blinkstar2 {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
  50% {
    -webkit-transform: translateZ(150px) rotateY(190deg);
            transform: translateZ(150px) rotateY(190deg);
  }
  80% {
    -webkit-transform: translateZ(0) rotateY(360deg);
            transform: translateZ(0) rotateY(360deg);
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(360deg);
            transform: translateZ(0) rotateY(360deg);
  }
}
@media (max-width: 1600px) {
  .parallax__careers figure:nth-child(1) img {
    top: 7%;
    left: 0%;
  }
}
@media (max-width: 1400px) {
  .parallax__careers figure:nth-child(1) img {
    top: 4%;
    left: 0%;
  }
}
.parallax__careers figure:nth-child(2) img {
  top: -25px;
  left: 68vw;
  position: relative;
  -webkit-animation: rotateYLogo3 2s infinite ease-in-out;
          animation: rotateYLogo3 2s infinite ease-in-out;
}
@-webkit-keyframes rotateYLogo3 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
@keyframes rotateYLogo3 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
.parallax__careers figure:nth-child(3) img {
  top: -50px;
  left: 96vw;
  position: relative;
  -webkit-animation: shake3 5s infinite;
          animation: shake3 5s infinite;
}
@-webkit-keyframes shake3 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
@keyframes shake3 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
.parallax__careers figure:nth-child(4) img {
  top: 290px;
  left: 30px;
  position: relative;
  -webkit-animation: shake1 5s infinite;
          animation: shake1 5s infinite;
}
@keyframes shake1 {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  25% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
  }
}
@media (max-width: 1400px) {
  .parallax__careers figure:nth-child(4) img {
    top: 38%;
    left: 0%;
  }
}
.parallax__careers figure:nth-child(5) img {
  top: 40vh;
  left: 140px;
  position: relative;
  -webkit-animation: rotateYLogo1 2s infinite ease-in-out;
          animation: rotateYLogo1 2s infinite ease-in-out;
}
@keyframes rotateYLogo1 {
  0% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  50% {
    -webkit-transform: translateZ(150px) scale(0.7);
            transform: translateZ(150px) scale(0.7);
  }
  80% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
  }
  100% {
    -webkit-transform: translateZ(0) cale(1);
            transform: translateZ(0) cale(1);
  }
}
@media (max-width: 1400px) {
  .parallax__careers figure:nth-child(5) img {
    bottom: 35%;
    left: 0%;
  }
}
.parallax__careers figure:nth-child(6) img {
  top: 25vh;
  left: 90vw;
  position: relative;
  -webkit-animation: dot-jump 5s infinite;
          animation: dot-jump 5s infinite;
}
@keyframes dot-jump {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  80% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@media (max-width: 1600px) {
  .parallax__careers figure:nth-child(6) img {
    bottom: 56%;
    right: 0%;
  }
}
.parallax__careers figure:nth-child(7) img {
  top: 50vh;
  left: 97vw;
  position: relative;
  -webkit-animation: dot-jump 5s infinite;
          animation: dot-jump 5s infinite;
}
@keyframes dot-jump {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  50% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  80% {
    -webkit-transform: translateY(25px);
            transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@media (max-width: 1600px) {
  .parallax__careers figure:nth-child(7) img {
    bottom: 28%;
    left: 0%;
  }
}

.banner {
  margin: 120px 0 60px;
  position: relative;
  min-height: 815px;
}
@media (max-width: 600px) {
  .banner {
    margin: 80px 0 40px;
  }
}
@media (max-width: 1199px) {
  .banner__content__wrapper {
    min-height: 460px;
  }
}
@media (max-width: 992px) {
  .banner__content__wrapper {
    padding: 70px 0 0 0;
    min-height: 410px;
  }
}
@media (max-width: 767px) {
  .banner__content__wrapper {
    padding: 50px 0 0 0;
    min-height: 295px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.banner__content__wrapper h2 {
  font-size: 55px;
  font-weight: 800;
  color: #006bdc;
  font-family: "Manrope", sans-serif;
  position: relative;
  line-height: 58px;
}
.banner__content__wrapper h2:after {
  background-color: #d60812;
  content: "";
  position: absolute;
  width: 39px;
  height: 7px;
  bottom: -30px;
  z-index: 1;
  border-radius: 10px;
  left: 0;
}
@media (max-width: 600px) {
  .banner__content__wrapper h2:after {
    bottom: -25px;
  }
}
@media (max-width: 1400px) {
  .banner__content__wrapper h2 {
    font-size: 50px;
  }
}
@media (max-width: 1199px) {
  .banner__content__wrapper h2 {
    font-size: 50px;
  }
}
@media (max-width: 600px) {
  .banner__content__wrapper h2 {
    font-size: 32px;
    line-height: 38px;
  }
}
.banner__content__wrapper p {
  font-size: 18px;
  color: #241e1e;
  margin: 55px 0 25px 0;
  line-height: 30px;
}
@media (max-width: 1400px) {
  .banner__content__wrapper p {
    font-size: 15px;
    line-height: 28px;
  }
}
@media (max-width: 600px) {
  .banner__content__wrapper p {
    font-size: 14px;
    line-height: 22px;
    margin: 40px 0 25px 0;
    text-align: justify;
  }
}
.banner__left__link {
  position: fixed;
  bottom: 37%;
  font-size: 16px;
  left: -168px;
  text-align: right;
  width: 385px;
  border-radius: 0 20px 20px 0px;
  letter-spacing: 1.5px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  font-weight: 400;
  z-index: 10;
}
.banner__left__link:after {
  content: "";
  display: block;
  width: 40px;
  height: 1px;
  background-color: #4D4D4D;
  position: absolute;
  right: -50px;
  top: 17px;
}
@media (max-width: 1400px) {
  .banner__left__link:after {
    top: 10px;
  }
}
.banner__left__link:before {
  content: "";
  display: block;
  width: 40px;
  height: 1px;
  background-color: #4D4D4D;
  position: absolute;
  left: 50px;
  top: 16px;
}
@media (max-width: 1400px) {
  .banner__left__link:before {
    left: 104px;
    top: 9px;
  }
}
@media (max-width: 1400px) {
  .banner__left__link {
    font-size: 14px;
    width: 370px;
  }
}
@media (max-width: 1199px) {
  .banner__left__link {
    display: none;
  }
}
@media (max-width: 767px) {
  .banner__left__link {
    bottom: 3px;
  }
}
@media (max-width: 576px) {
  .banner__left__link {
    padding: 15px 25px;
    width: 410px;
    bottom: 15px;
  }
}
.banner__left__link span {
  font-size: 14px;
}
@media (max-width: 576px) {
  .banner__left__link span {
    font-size: 12px;
  }
}
.banner__left__link a {
  color: #4D4D4D;
  display: inline-block;
  padding: 0 5px;
}
.banner__left__link a:hover {
  color: #006BDC;
}
@media (max-width: 1400px) {
  .banner__left__link a {
    width: 95px;
  }
}
.banner__social__medias__holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: fixed;
  right: 0px;
  bottom: 32%;
  color: #4d4d4d;
  z-index: 10;
}
@media (max-width: 600px) {
  .banner__social__medias__holder {
    display: none;
  }
}
.banner__social__medias__holder a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #4d4d4d;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 20px 10px 0;
  border: 2px solid #4d4d4d;
  background-color: #fff;
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
}
.banner__social__medias__holder a:hover:nth-child(1) {
  background-color: #f8d301;
  background-image: -webkit-gradient(linear, left top, right top, from(#f8d301), color-stop(29%, #f8060b), color-stop(60%, #bf2f7c), to(#7f3cb1));
  background-image: linear-gradient(90deg, #f8d301 0%, #f8060b 29%, #bf2f7c 60%, #7f3cb1 100%);
  color: #fff;
  border: transparent;
}
.banner__social__medias__holder a:hover:nth-child(2) {
  background-color: #45619d;
  color: #fff;
  border: transparent;
}
.banner__social__medias__holder a:hover:nth-child(3) {
  background-color: #0077b7;
  color: #fff;
  border: transparent;
}
@media (max-width: 1400px) {
  .banner__social__medias__holder a {
    width: 30px;
    height: 30px;
    margin: 0 15px 10px 0;
  }
}
.banner__social__medias__holder a i {
  font-size: 14px;
}
.banner__social__medias__holder p {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  margin-bottom: 0;
  margin-top: 37px;
  letter-spacing: 1.5px;
  transform: rotate(270deg);
  margin-bottom: 20px;
  margin-top: 37px;
  letter-spacing: 1.5px;
  margin-right: -7px;
}
@media (max-width: 1400px) {
  .banner__social__medias__holder p {
    font-size: 15px;
    margin-right: -19px;
  }
}
@media (max-width: 767px) {
  .banner__social__medias__holder {
    padding-bottom: 15px;
  }
}
.banner__social__medias__holder:after {
  content: "";
  display: block;
  width: 2px;
  height: 62px;
  background-color: #4d4d4d;
  position: absolute;
  bottom: -90px;
  right: 40px;
}
@media (max-width: 1400px) {
  .banner__social__medias__holder:after {
    bottom: -90px;
    right: 29px;
  }
}
.banner__social__medias__holder:before {
  content: "";
  display: block;
  width: 2px;
  height: 62px;
  background-color: #4d4d4d;
  position: absolute;
  top: -72px;
  right: 40px;
}
@media (max-width: 1400px) {
  .banner__social__medias__holder:before {
    top: -72px;
    right: 30px;
  }
}
.banner .slick-slider-1 .slick-dots {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 10px 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: -35px;
}
.banner .slick-slider-1 .slick-dots .slick-active button {
  background: #fff;
  opacity: 1;
  border: 4px solid #006bdc;
  height: 16px;
  width: 16px;
}
.banner .slick-slider-1 .slick-dots li {
  display: inline-block;
  margin: 0 14px 0 0;
}
.banner .slick-slider-1 .slick-dots li button {
  border: 0;
  background: #888787;
  display: block;
  height: 8px;
  width: 8px;
  outline: 0;
  color: transparent;
  cursor: pointer;
  border-radius: 20px;
  opacity: 1;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  -webkit-box-shadow: 1px 2px 8px rgba(36, 65, 93, 0.3);
          box-shadow: 1px 2px 8px rgba(36, 65, 93, 0.3);
  position: relative;
  padding: 0;
}
.banner .slick-slider-1 .slick-dots li button:before {
  display: none;
}
.banner .slick-slider-1 .banner__slider__item__holder {
  margin-top: 55px;
}
@media (max-width: 992px) {
  .banner .slick-slider-1 .banner__slider__item__holder {
    margin-top: 10px;
  }
}
.banner .slick-slider-1 .banner__slider__item__holder figure img {
  margin: 0 auto;
}
.banner .slick-slider-1 .banner__slider__item__holder h4 {
  color: #999999;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  position: relative;
  margin-top: -27px;
}
@media (max-width: 600px) {
  .banner .slick-slider-1 .banner__slider__item__holder h4 {
    font-size: 12px;
  }
}
.banner .slick-slider-1 .banner__slider__item__holder h4:before {
  content: "";
  display: block;
  width: 16px;
  height: 1px;
  background-color: #999999;
  position: absolute;
  left: 40%;
  top: 9px;
}
@media (max-width: 992px) {
  .banner .slick-slider-1 .banner__slider__item__holder h4:before {
    left: 43%;
  }
}
@media (max-width: 600px) {
  .banner .slick-slider-1 .banner__slider__item__holder h4:before {
    left: 37%;
  }
}
@media (max-width: 425px) {
  .banner .slick-slider-1 .banner__slider__item__holder h4:before {
    left: 32%;
  }
}
.banner .slider-holder {
  position: relative;
  pointer-events: all !important;
}

.services {
  padding: 80px 0px;
  min-height: 700px;
  background: #F7F9FC;
  position: relative;
  overflow: hidden;
}
@media (max-width: 768px) {
  .services {
    padding: 40px 0px;
  }
}
.services:after {
  content: "";
  position: absolute;
  background: url(../images/bg/tiny-dotted-arrow.png) center no-repeat;
  width: 203px;
  height: 194px;
  left: -74px;
  bottom: 190px;
  background-size: contain;
}
@media (max-width: 768px) {
  .services:after {
    width: 135px;
    height: 78px;
    left: -60px;
    bottom: -20px;
  }
}
.services:before {
  content: "";
  position: absolute;
  background: url(../images/bg/small-arrow-dotted.png) center center no-repeat;
  width: 255px;
  height: 254px;
  right: -92px;
  top: 100px;
}
@media (max-width: 1400px) {
  .services:before {
    width: 190px;
    height: 245px;
    right: -65px;
    top: 40px;
    background-size: contain;
  }
}
@media (max-width: 1400px) and (max-width: 767px) {
  .services:before {
    width: 150px;
    height: 110px;
    right: -57px;
    top: 40px;
    background-size: contain;
  }
}
@media (max-width: 1400px) and (max-width: 425px) {
  .services:before {
    width: 80px;
    height: 50px;
    right: -32px;
    top: 4px;
  }
}
.services__item {
  border: 1px solid #fff;
  border-radius: 40px;
  background: #F7F9FC;
  -webkit-box-shadow: 0px 12px 27px 12px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 12px 27px 12px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
  padding: 15px;
  position: relative;
  display: inline-block;
  -webkit-transform: scale(1);
          transform: scale(1);
  overflow: hidden;
  margin-bottom: 50px;
}
.services__item:hover {
  background: #D60812;
  -webkit-box-shadow: -1px 9px 36px -14px black;
          box-shadow: -1px 9px 36px -14px black;
  border: 1px solid #d60812;
}
@media (max-width: 768px) {
  .services__item {
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .services__item {
    margin-top: 20px;
  }
}
.services__item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  background: #fff;
  text-align: center;
  border-radius: 30px;
  padding: 30px;
}
@media (max-width: 425px) {
  .services__item a {
    padding: 20px;
  }
}
.services__item a span {
  font-size: 21px;
  color: #575f6b;
  font-weight: 700;
  margin: 15px 0px;
}
@media (max-width: 425px) {
  .services__item a span {
    font-size: 16px;
    margin: 10px 0px;
  }
}
.services__item a p {
  font-size: 15px;
  color: #4D4D4D;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 425px) {
  .services__item a p {
    font-size: 13px;
  }
}

.our__works {
  padding: 80px 0 135px 0;
  position: relative;
}
@media (max-width: 768px) {
  .our__works {
    padding: 40px 0 40px 0;
  }
}
.our__works .slick-slide {
  -webkit-transition: all 1s;
  transition: all 1s;
}
.our__works .slick-slide.slick-active.slick-center.slick-current {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transition: all 1s;
  transition: all 1s;
}
@media (max-width: 1400px) {
  .our__works .slick-slide.slick-active.slick-center.slick-current {
    height: 500px;
  }
}
.our__works .slick-slide.slick-active.slick-center .btn__link--read-more {
  display: block;
}
.our__works .slick-slide.slick-active.slick-center .item__details h6 {
  font-size: 16px;
}
@media (max-width: 1199px) {
  .our__works .slick-slide.slick-active.slick-center .item__details h6 {
    font-size: 14px;
  }
}
.our__works .slick-slide.slick-active.slick-center .item__details h4 {
  margin-bottom: 15px;
}
.our__works .slick-slide.slick-active.slick-center .item__details p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 18px;
}
@media (max-width: 1199px) {
  .our__works .slick-slide.slick-active.slick-center .item__details p {
    font-size: 12px;
    line-height: 22px;
  }
}
.our__works .slick-slide.slick-active.slick-center .slider_content_item {
  margin-top: 0;
}
.our__works .slick-prev {
  background-image: url(../images/icons/chevron-left.svg);
  right: 51%;
  left: auto;
  top: auto;
}
.our__works .slick-next {
  background-image: url(../images/icons/chevron-right.svg);
  left: 51%;
  top: auto;
}
.our__works .slick-arrow {
  border: 0;
  background-color: #006BDC;
  display: block;
  height: 50px;
  width: 50px;
  outline: 0;
  color: transparent;
  cursor: pointer;
  border-radius: 50%;
  opacity: 1;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  position: absolute;
  padding: 0;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  bottom: -11%;
}
@media (max-width: 768px) {
  .our__works .slick-arrow {
    height: 35px;
    width: 35px;
    bottom: 0%;
    background-size: 8px;
  }
}
.our__works .slick-arrow:hover {
  background-color: #4D4D4D;
}
@media (max-width: 600px) {
  .our__works .slick-arrow {
    height: 40px;
    width: 40px;
    bottom: 0%;
  }
}
.our__works .slick-arrow:before {
  display: none;
}
.our__works .slider_content {
  margin: 70px 20px 120px 20px;
  display: block;
  float: left;
}
@media (max-width: 992px) {
  .our__works .slider_content {
    margin: 25px 20px 60px 20px;
  }
}
@media (max-width: 768px) {
  .our__works .slider_content {
    margin: 25px 20px 100px 20px;
  }
}
.our__works .slider_content_item {
  min-height: 465px;
  background-color: #f6f6f6;
  border: 1px solid #fff;
  border-radius: 40px;
  padding: 15px;
  -webkit-box-shadow: 0px 12px 27px 12px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 12px 27px 12px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin-right: 20px;
  margin: 35px 10px 0px;
}
@media (max-width: 768px) {
  .our__works .slider_content_item {
    min-height: 425px;
  }
}
.our__works .slider_content_holder {
  background: #fff;
  border-radius: 30px;
  padding: 15px;
}
.our__works .slider_content_holder figure {
  width: 100%;
  border-radius: 15px;
  height: 260px;
  overflow: hidden;
}
.our__works .slider_content_holder figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.our__works .slider_content_holder .item__details {
  padding: 15px 22px 0 15px;
}
.our__works .slider_content_holder .item__details p {
  font-size: 13px;
  line-height: 1.6;
  margin-bottom: 18px;
  font-weight: 500;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.our__works .slider_content_holder .item__details h4 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
}
.our__works .slider_content_holder .item__details h6 {
  font-size: 12px;
  color: #ffb71b;
  margin-bottom: 0;
  padding: 0 0 4px 0;
}
.our__works .slider_content_holder .item__details .btn__link--read-more {
  display: none;
}

.blog {
  padding: 80px 0px;
  background-image: url(../images/place-holder/global-bg-blog.png);
  background-color: #006BDC;
  background-size: cover;
  background-position: bottom;
}
@media (max-width: 768px) {
  .blog {
    padding: 40px 0px;
  }
}
.blog__latest__outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.blog__main {
  background: #006bdca3;
  color: #fff;
  border: 1px solid #0A8DFF;
  -webkit-box-shadow: 0px 2px 18px -12px rgba(0, 0, 0, 0.55);
          box-shadow: 0px 2px 18px -12px rgba(0, 0, 0, 0.55);
  padding: 20px;
  margin-top: 30px;
  border-radius: 40px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.blog__main:hover {
  background: #fff;
}
.blog__main:hover h4,
.blog__main:hover h6 {
  color: #000;
}
@media (max-width: 767px) {
  .blog__main {
    margin: 45px 0 25px 0;
  }
}
@media (max-width: 575px) {
  .blog__main {
    padding: 15px;
  }
}
.blog__main figure {
  height: 260px;
}
@media (max-width: 768px) {
  .blog__main figure {
    min-height: 260px;
  }
}
.blog__main figure img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.blog__main h6 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 14px;
}
@media (max-width: 425px) {
  .blog__main h6 {
    margin-top: 10px;
    font-size: 12px;
  }
}
.blog__main h4 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 800;
  margin-bottom: 10px;
}
@media (max-width: 992px) {
  .blog__main h4 {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width: 992px) {
  .blog__main h4 {
    font-size: 20px;
    line-height: 30px;
  }
}
.blog .published__blog__wrapper {
  padding-right: 25px;
  position: relative;
  overflow-y: scroll;
  height: 639px;
  overflow-x: hidden;
  padding-left: 65px;
}
@media (max-width: 992px) {
  .blog .published__blog__wrapper {
    padding-left: 0px;
    height: 600px;
    padding-right: 10px;
  }
}
.blog .published__blog__wrapper .blog__list {
  background: #006bdca3;
  color: #fff;
  border: 1px solid #0A8DFF;
  -webkit-box-shadow: 0px 3px 29px -17px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 3px 29px -17px rgba(0, 0, 0, 0.75);
  padding: 18px;
  border-radius: 35px;
  margin: 0 0 45px 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.blog .published__blog__wrapper .blog__list:nth-last-child(1) {
  margin-bottom: 0;
}
.blog .published__blog__wrapper .blog__list:hover {
  background: #fff;
}
.blog .published__blog__wrapper .blog__list:hover .blog__list__details p,
.blog .published__blog__wrapper .blog__list:hover .blog__list__details h5,
.blog .published__blog__wrapper .blog__list:hover .blog__list__details h6 {
  color: #000;
}
@media (max-width: 992px) {
  .blog .published__blog__wrapper .blog__list {
    margin: 0 0 25px 0;
  }
}
@media (max-width: 575px) {
  .blog .published__blog__wrapper .blog__list {
    padding: 13px;
  }
}
.blog .published__blog__wrapper .blog__list a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog .published__blog__wrapper .blog__list a figure {
  width: 230px;
  height: 145px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
@media (max-width: 575px) {
  .blog .published__blog__wrapper .blog__list a figure {
    width: 100%;
    height: 200px;
  }
}
.blog .published__blog__wrapper .blog__list a figure img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
@media (max-width: 575px) {
  .blog .published__blog__wrapper .blog__list a {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.blog .published__blog__wrapper .blog__list__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 25px;
}
@media (max-width: 575px) {
  .blog .published__blog__wrapper .blog__list__details {
    padding-left: 0px;
  }
}
.blog .published__blog__wrapper .blog__list__details h6 {
  font-size: 15px;
  color: #FFB71B;
}
@media (max-width: 425px) {
  .blog .published__blog__wrapper .blog__list__details h6 {
    font-size: 13px;
    margin-top: 10px;
  }
}
.blog .published__blog__wrapper .blog__list__details p {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 24px;
}
@media (max-width: 425px) {
  .blog .published__blog__wrapper .blog__list__details p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}
.blog .published__blog__wrapper .blog__list__details h5 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
@media (max-width: 425px) {
  .blog .published__blog__wrapper .blog__list__details h5 {
    font-size: 13px;
  }
}

#scroll-news::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(61, 61, 61, 0.644);
}

::-webkit-scrollbar-thumb {
  background: #FFB71B;
  border-radius: 20px;
}

.job__opening {
  padding: 80px 0px;
  position: relative;
}
@media (max-width: 768px) {
  .job__opening {
    padding: 40px 0px;
  }
}
.job__opening__wrapper {
  background: #F9F9F9;
  display: inline-block;
  border: 1px solid #fff;
  -webkit-box-shadow: 0px 1px 21px -13px rgba(0, 0, 0, 0.75);
          box-shadow: 0px 1px 21px -13px rgba(0, 0, 0, 0.75);
  border-radius: 30px;
  padding: 15px;
  width: 100%;
  margin-bottom: 30px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .job__opening__wrapper {
    margin-bottom: 15px;
  }
}
@media (max-width: 425px) {
  .job__opening__wrapper {
    padding: 10px;
  }
}
.job__opening__wrapper:hover {
  background: #227FE1;
  -webkit-box-shadow: 0px 6px 17px -5px #000000e8;
          box-shadow: 0px 6px 17px -5px #000000e8;
  border: 1px solid #006BDC;
}
.job__opening__wrapper .job__opening__holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 20px;
  padding: 20px 30px 20px 10px;
}
@media (max-width: 425px) {
  .job__opening__wrapper .job__opening__holder {
    padding: 15px 10px 15px 10px;
  }
}
.job__opening__wrapper .job__opening__holder .job__details {
  padding-left: 30px;
}
@media (max-width: 768px) {
  .job__opening__wrapper .job__opening__holder .job__details {
    padding-left: 20px;
  }
}
.job__opening__wrapper .job__opening__holder .job__details h6 {
  color: #2A2A30;
  font-weight: 600;
  font-size: 19px;
}
@media (max-width: 425px) {
  .job__opening__wrapper .job__opening__holder .job__details h6 {
    font-size: 14px;
  }
}
.job__opening__wrapper .job__opening__holder .job__details p {
  color: #7E7E7E;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}
@media (max-width: 425px) {
  .job__opening__wrapper .job__opening__holder .job__details p {
    font-size: 13px;
    margin-bottom: 5px;
  }
}
.job__opening__wrapper .job__opening__holder .job__details p span {
  color: #2A2A30;
  font-weight: 600;
  font-size: 14px;
}
@media (max-width: 425px) {
  .job__opening__wrapper .job__opening__holder .job__details p span {
    font-size: 13px;
  }
}
.job__opening__wrapper .job__opening__holder .job__details h5 {
  color: #2A2A30;
  font-size: 14px;
}
@media (max-width: 425px) {
  .job__opening__wrapper .job__opening__holder .job__details h5 {
    font-size: 13px;
  }
}
.job__opening__quote {
  color: #4D4D4D;
  font-size: 23px;
  text-align: center;
  line-height: 34px;
  font-weight: 500;
  max-width: 78%;
  margin: 0 auto 40px;
  white-space: break-spaces;
}
@media (max-width: 992px) {
  .job__opening__quote {
    font-size: 20px;
    line-height: 31px;
  }
}
@media (max-width: 767px) {
  .job__opening__quote {
    margin: 15px auto 25px auto;
    font-size: 15px;
    line-height: 26px;
    max-width: 100%;
  }
}
.job__opening .modal-dialog {
  max-width: 1410px;
  margin: 170px auto 130px;
}
.job__opening .modal-dialog .modal-content {
  border-radius: 20px;
  border: none;
}

.get__in__touch {
  background-color: #006BDC;
  padding: 40px 0;
  position: relative;
  -webkit-box-shadow: 0px -12px 57px -30px #000000;
          box-shadow: 0px -12px 57px -30px #000000;
}
@media (max-width: 992px) {
  .get__in__touch {
    padding: 35px 0;
  }
}
@media (max-width: 600px) {
  .get__in__touch {
    padding: 30px 0;
  }
}
.get__in__touch .container {
  position: relative;
}
.get__in__touch .container:before {
  content: "";
  width: 190px;
  display: block;
  height: 250px;
  background-image: url(../images/icons/scroll-lines.svg);
  background-size: 240px;
  position: absolute;
  top: -87px;
  right: -125px;
  background-repeat: no-repeat;
}
@media (max-width: 1600px) {
  .get__in__touch .container:before {
    right: -30px;
  }
}
@media (max-width: 1300px) {
  .get__in__touch .container:before {
    right: 44px;
  }
}
@media (max-width: 992px) {
  .get__in__touch .container:before {
    width: 264px;
    height: 277px;
    background-size: cover;
    top: -82px;
    right: 0;
  }
}
@media (max-width: 768px) {
  .get__in__touch .container:before {
    display: none;
  }
}
.get__in__touch .container .to-top {
  position: fixed;
  right: 193px;
  cursor: pointer;
  width: 2px;
  height: 2px;
  background: #4d4d4d1f;
  border-radius: 50%;
  bottom: 10%;
  z-index: 1;
  -webkit-animation: boom-out2 1.5s linear infinite;
          animation: boom-out2 1.5s linear infinite;
}
@-webkit-keyframes boom-out2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #4d4d4d;
            box-shadow: 0 0 0 0 #4d4d4d;
  }
  100% {
    -webkit-box-shadow: 0 0 0 40px rgba(77, 77, 77, 0);
            box-shadow: 0 0 0 40px rgba(77, 77, 77, 0);
  }
}
@keyframes boom-out2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #4d4d4d;
            box-shadow: 0 0 0 0 #4d4d4d;
  }
  100% {
    -webkit-box-shadow: 0 0 0 40px rgba(77, 77, 77, 0);
            box-shadow: 0 0 0 40px rgba(77, 77, 77, 0);
  }
}
.get__in__touch .container .to-top.pos-change {
  position: absolute;
  right: -120px;
  top: -95px;
}
@media (max-width: 1600px) {
  .get__in__touch .container .to-top.pos-change {
    right: -25px;
  }
}
@media (max-width: 1300px) {
  .get__in__touch .container .to-top.pos-change {
    right: 50px;
  }
}
@media (max-width: 992px) {
  .get__in__touch .container .to-top.pos-change {
    right: 60px;
  }
}
@media (max-width: 768px) {
  .get__in__touch .container .to-top.pos-change {
    display: none;
  }
}
@media (max-width: 1600px) {
  .get__in__touch .container .to-top {
    right: 50px;
  }
}
@media (max-width: 1300px) {
  .get__in__touch .container .to-top {
    right: 60px;
  }
}
@media (max-width: 992px) {
  .get__in__touch .container .to-top {
    right: 72px;
  }
}
@media (max-width: 768px) {
  .get__in__touch .container .to-top {
    display: none;
  }
}
.get__in__touch .container .to-top:after {
  content: "";
  display: block;
  width: 41px;
  height: 35px;
  background-image: url(../images/icons/yellow-arrow.svg);
  position: absolute;
  right: -23px;
  top: -13px;
  background-repeat: no-repeat;
}
.get__in__touch__quote {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 992px) {
  .get__in__touch__quote {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.location {
  padding: 80px 0px;
  background: url(../images/bg/bg-world-globe.jpg) no-repeat center;
  position: relative;
  -webkit-box-shadow: 0px 14px 21px -22px black;
          box-shadow: 0px 14px 21px -22px black;
  background-size: cover;
}
@media (max-width: 768px) {
  .location {
    padding: 30px 0px;
  }
}
@media (max-width: 992px) {
  .location {
    background-position-x: center;
    background-position-y: top;
    background-size: auto;
  }
}
.location:after {
  content: "";
  position: absolute;
  background: url(../images/bg/blue-dotted-below.png) no-repeat;
  width: 284px;
  height: 181px;
  left: 0;
  bottom: -31px;
  background-size: 280px;
  background-position: bottom;
}
@media (max-width: 1199px) {
  .location:after {
    background-size: 240px;
  }
}
@media (max-width: 992px) {
  .location:after {
    background-size: contain;
    width: 140px;
    height: 135px;
  }
}
@media (max-width: 425px) {
  .location:after {
    width: 105px;
  }
}
.location:before {
  content: "";
  position: absolute;
  background: url(../images/bg/blue-dotted-bg.png) no-repeat;
  width: 259px;
  height: 200px;
  right: 0;
  top: 0px;
  background-size: contain;
}
@media (max-width: 992px) {
  .location:before {
    width: 160px;
    height: 125px;
  }
}
@media (max-width: 992px) {
  .location:before {
    width: 110px;
    height: 85px;
  }
}
@media (max-width: 425px) {
  .location:before {
    width: 70px;
    height: 52px;
  }
}
.location__holder {
  background-color: #F7F9FC;
  border-radius: 40px;
  -webkit-box-shadow: 0px 6px 29px -10px rgba(0, 0, 0, 0.23);
          box-shadow: 0px 6px 29px -10px rgba(0, 0, 0, 0.23);
  position: relative;
  overflow: hidden;
  margin: 142px 0 10px 0;
  height: 410px;
  padding: 20px;
}
@media (max-width: 992px) {
  .location__holder {
    margin: 210px 0 10px 0;
  }
  .location__holder.null__margin {
    margin-top: 0;
  }
}
@media (max-width: 992px) and (max-width: 768px) {
  .location__holder {
    margin: 255px 0 10px 0;
  }
}
@media (max-width: 992px) and (max-width: 600px) {
  .location__holder {
    margin: 280px 0 10px 0;
  }
}
@media (max-width: 992px) and (max-width: 450px) {
  .location__holder {
    height: 340px;
  }
}
@media (max-width: 992px) and (max-width: 425px) {
  .location__holder {
    margin: 315px 0 10px 0;
  }
}
@media (max-width: 992px) and (max-width: 370px) {
  .location__holder {
    height: 260px;
  }
}
.location__holder .parent__company {
  height: 100%;
}
.location__holder .parent__company img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  border-radius: 30px;
  height: 100%;
  width: 100%;
}
.location__holder .native__company {
  height: 100%;
}
.location__holder .native__company img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  height: 100%;
  width: 100%;
  border-radius: 30px;
}
.location__holder__address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #f7f9fc;
  position: absolute;
  top: 50%;
  left: 50%;
  background: #006bdceb;
  border-radius: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px;
  width: 410px;
  margin: -60px -210px;
}
@media (max-width: 1199px) {
  .location__holder__address {
    margin: -60px -172px;
    width: 340px;
  }
}
@media (max-width: 992px) {
  .location__holder__address {
    width: 340px;
  }
}
@media (max-width: 450px) {
  .location__holder__address {
    margin: -60px -144px;
    width: 280px;
  }
}
@media (max-width: 370px) {
  .location__holder__address {
    margin: -60px -110px;
    width: 220px;
  }
}
.location__holder__address:before {
  content: "";
  position: absolute;
  left: 45%;
  border-width: 15px;
  border-style: solid;
  border-color: #006bdceb transparent transparent transparent;
  bottom: -30px;
}
.location__holder__address figure {
  max-width: 90px;
  height: 90px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
@media (max-width: 450px) {
  .location__holder__address figure {
    max-width: 80px;
    height: 80px;
  }
}
@media (max-width: 370px) {
  .location__holder__address figure {
    max-width: 60px;
    height: 60px;
  }
}
.location__holder__address figure img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  -o-object-fit: none;
     object-fit: none;
}
.location__holder__address__details {
  padding: 0 0 0 15px;
}
.location__holder__address__details h4 {
  font-size: 19px;
  margin-bottom: 0px;
}
@media (max-width: 1199px) {
  .location__holder__address__details h4 {
    font-size: 17px;
  }
}
@media (max-width: 450px) {
  .location__holder__address__details h4 {
    font-size: 15px;
  }
}
@media (max-width: 370px) {
  .location__holder__address__details h4 {
    font-size: 13px;
  }
}
.location__holder__address__details p {
  font-size: 16px;
  margin-bottom: 0;
  display: block;
}
@media (max-width: 450px) {
  .location__holder__address__details p {
    font-size: 12px;
  }
}
@media (max-width: 370px) {
  .location__holder__address__details p {
    font-size: 12px;
  }
}
.location__holder__address__details a {
  background-color: #0a8dff;
  position: absolute;
  width: 28px;
  height: 28px;
  right: 11px;
  z-index: 1;
  border-radius: 5px;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 450px) {
  .location__holder__address__details a {
    width: 22px;
    height: 22px;
    background-size: 16px;
  }
}
.location__name {
  font-size: 45px;
  color: #4D4D4D;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 992px) {
  .location__name {
    font-size: 35px;
  }
}
@media (max-width: 600px) {
  .location__name {
    font-size: 25px;
  }
}
.location__one {
  position: absolute;
  top: 0;
  right: 50%;
  width: 5px;
  height: 5px;
  margin: 305px 95px 0 0;
  background-color: #006BDC;
  border-radius: 50%;
  -webkit-animation: boom-out1 1.5s linear infinite;
          animation: boom-out1 1.5s linear infinite;
}
@media (max-width: 992px) {
  .location__one {
    margin: 290px 95px 0 0;
  }
}
@-webkit-keyframes boom-out1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #006bdc;
            box-shadow: 0 0 0 0 #006bdc;
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
            box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
  }
}
@keyframes boom-out1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #006bdc;
            box-shadow: 0 0 0 0 #006bdc;
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
            box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
  }
}
.location__one:before {
  content: "";
  background: url(../images/icons/applab-location-icon.png) no-repeat;
  background-position: center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: -30px;
  background-size: contain;
  right: -19px;
}
.location__two {
  position: absolute;
  top: 0;
  right: 50%;
  width: 5px;
  height: 5px;
  margin: 410px -82px;
  background-color: #006BDC;
  border-radius: 50%;
  -webkit-animation: boom-out 1.5s linear infinite;
          animation: boom-out 1.5s linear infinite;
}
@media (max-width: 992px) {
  .location__two {
    margin: 395px -80px;
  }
}
@-webkit-keyframes boom-out {
  0% {
    -webkit-box-shadow: 0 0 0 0 #006bdc;
            box-shadow: 0 0 0 0 #006bdc;
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
            box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
  }
}
@keyframes boom-out {
  0% {
    -webkit-box-shadow: 0 0 0 0 #006bdc;
            box-shadow: 0 0 0 0 #006bdc;
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
            box-shadow: 0 0 0 20px rgba(0, 107, 220, 0);
  }
}
.location__two:before {
  content: "";
  background: url(../images/icons/appstation-icon.png) no-repeat;
  background-position: center;
  position: absolute;
  width: 40px;
  height: 40px;
  top: -30px;
  background-size: contain;
  right: -19px;
}

.clients {
  background: #F7F9FC;
  padding: 60px 0px;
}
@media (max-width: 767px) {
  .clients {
    padding: 50px 0px;
  }
}
.clients__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.clients__list li figure {
  background: #fff;
  border: 1px solid #ebebeb;
  border-radius: 12px;
  padding: 10px;
  margin-right: 20px;
}
.clients__list li figure:hover > img {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
.clients__list li figure img {
  width: auto;
  display: block;
  margin: 0 auto;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.events {
  padding: 80px 0px;
  background-image: url(../images/bg/bg-curves.png);
  background-color: #F7F9FC;
  background-size: cover;
  -webkit-box-shadow: 0px 20px 20px -21px #00000061;
          box-shadow: 0px 20px 20px -21px #00000061;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .events {
    padding: 40px 0px;
  }
}
.events .slick-list {
  overflow: visible;
  margin-bottom: 80px;
  padding-top: 20px;
}
.events .slick-slide:nth-child(3n) {
  margin-top: 40px;
}
.events .slick-slide:nth-child(3n-1) {
  margin-top: 80px;
}
.events .slick-next {
  background-image: url(../images/icons/chevron-right.svg);
  left: 51%;
  right: auto;
  top: auto;
}
@media (max-width: 600px) {
  .events .slick-next {
    background-size: 10px;
  }
}
.events .slick-prev {
  background-image: url(../images/icons/chevron-left.svg);
  right: 51%;
  left: auto;
  top: auto;
}
@media (max-width: 600px) {
  .events .slick-prev {
    background-size: 10px;
  }
}
.events .slick-arrow {
  border: 0;
  background-color: #D60812;
  display: block;
  height: 50px;
  width: 50px;
  outline: 0;
  color: transparent;
  cursor: pointer;
  border-radius: 50%;
  opacity: 1;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  position: absolute;
  padding: 0;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  bottom: -11%;
  top: auto;
}
.events .slick-arrow:before {
  display: none;
}
.events .slick-arrow:hover {
  background-color: #4D4D4D;
}
@media (max-width: 768px) {
  .events .slick-arrow {
    background-size: 8px;
    height: 35px;
    width: 35px;
  }
}
.events_holder {
  background-color: #ebedef;
  border: 1px solid #fff;
  border-radius: 60px;
  padding: 20px;
  -webkit-box-shadow: 0px 6px 35px -18px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 35px -18px rgba(0, 0, 0, 0.75);
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
  margin-right: 20px;
}
.events_holder:hover .events_pic_overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.events_figure {
  margin: auto;
  position: relative;
  /* width: 320px; */
  height: 340px;
}
.events_figure img {
  border-radius: 40px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.events_pic_overlay {
  position: absolute;
  left: 18px;
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
  background-color: #d60812;
  height: 89%;
  width: 86%;
  padding: 20px;
  overflow: hidden;
  top: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-radius: 30px;
  opacity: 0.9;
}
.events_pic_overlay figure {
  margin: auto;
}
.events_pic_overlay h6 {
  font-size: 28px;
  color: #F7F9FC;
}

.fancybox-custom .fancybox__backdrop {
  background-color: rgba(255, 255, 255, 0.45);
  -webkit-backdrop-filter: blur(13.5px);
          backdrop-filter: blur(13.5px);
  -webkit-animation: fade-in2 1s linear;
          animation: fade-in2 1s linear;
}
@-webkit-keyframes fade-in2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fancybox-custom .fancybox__backdrop:before {
  content: "";
  position: absolute;
  background: url(../images/bg/red-circle.png) no-repeat;
  width: 154px;
  height: 154px;
  right: -60px;
  top: 57px;
  -webkit-animation: rotate-in-up-left2 3s ease;
          animation: rotate-in-up-left2 3s ease;
}
@-webkit-keyframes rotate-in-up-left2 {
  0% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-up-left2 {
  0% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
.fancybox-custom .fancybox__backdrop:after {
  content: "";
  position: absolute;
  background: url(../images/bg/red-circle.png) no-repeat;
  width: 154px;
  height: 154px;
  left: -80px;
  bottom: 60px;
  -webkit-animation: rotate-in-down-left2 3s ease;
          animation: rotate-in-down-left2 3s ease;
}
@-webkit-keyframes rotate-in-down-left2 {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-down-left2 {
  0% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
.fancybox-custom .fancybox__content {
  padding: 15px;
  background: #f9f9f9;
  border-radius: 30px;
  -webkit-box-shadow: 0px 0px 15px 6px rgba(42, 125, 225, 0.39);
          box-shadow: 0px 0px 15px 6px rgba(42, 125, 225, 0.39);
}
.fancybox-custom .fancybox__content:after {
  content: "";
  position: absolute;
  background: url(../images/logo/company-logo-big.png) no-repeat;
  width: 200px;
  height: 35px;
  left: 45px;
  top: 45px;
  background-size: contain;
}
.fancybox-custom .fancybox__content img {
  border-radius: 20px;
}
.fancybox-custom .fancybox__content .carousel__button.is-close {
  top: 45px;
  right: 45px;
  width: 35px;
  height: 35px;
  background: #006BDC;
  border-radius: 8px;
}
.fancybox-custom .fancybox__content .carousel__button.is-close:hover {
  background: #fff;
  color: #006BDC;
}
.fancybox-custom .fancybox__content .carousel__button.is-close:hover svg {
  -webkit-animation: rotate-close 1s;
          animation: rotate-close 1s;
  -webkit-transition: 0.3s cubic-bezier(0.62, 0.02, 0.34, 1);
  transition: 0.3s cubic-bezier(0.62, 0.02, 0.34, 1);
}
@-webkit-keyframes rotate-close {
  0% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-close {
  0% {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fancybox-custom .fancybox__thumbs {
  display: none;
}
.fancybox-custom .fancybox__caption {
  display: none;
}
.fancybox-custom .fancybox__nav .carousel__button.is-close {
  top: 28px;
  right: 30px;
  width: 50px;
  height: 50px;
  background: #006BDC;
  border-radius: 8px;
}
.fancybox-custom .fancybox__nav .carousel__button.is-prev {
  top: 85%;
  left: 47%;
  background-color: #D60812;
}
.fancybox-custom .fancybox__nav .carousel__button.is-prev:hover {
  background-color: #4D4D4D;
}
.fancybox-custom .fancybox__nav .carousel__button.is-next {
  top: 85%;
  right: 47%;
  background-color: #D60812;
}
.fancybox-custom .fancybox__nav .carousel__button.is-next:hover {
  background-color: #4D4D4D;
}