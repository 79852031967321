.loader-container {
    position: fixed;
    z-index: 1004;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}

.loader-inner {
    perspective: 200px;
    position: relative;
}

.loaderImage {
    -webkit-animation-name: spinner;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 2s;
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    transform-style: preserve-3d;
}

.loader-inner:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 80px;
    height: 1px;
    box-shadow: 0px 15px 12px 0px #00000080;
    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    transform-style: preserve-3d;
}

@-webkit-keyframes spinner {
    from {
        -webkit-transform: rotateY(0deg);
    }

    to {
        -webkit-transform: rotateY(-360deg);
    }
}

/* all other browsers */

@keyframes spinner {
    from {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    to {
        -moz-transform: rotateY(-360deg);
        -ms-transform: rotateY(-360deg);
        transform: rotateY(-360deg);
    }
}

.employee-form-container header h1,
.create-user h1,
.user-list-container h1,
.setting-container h1 {
    font-size: 25px;
    text-align: center;
    padding: 20px 0px;
    font-weight: bold;
    margin: 0;
    color: #0085D5;
    text-transform: uppercase;
}

.employee-form-container header h3 {
    font-size: 20px;
    text-align: center;
    color: #000000;
    margin: 0px;
}

.employee-details {
    padding: 20px 0;
    border: 1px solid #ced4da;
    margin: 20px 0;
}

.employee-details p {
    font-size: 14px;
    color: #707070;
    margin: 5px 0;
}

.employee-details p strong {
    text-transform: uppercase;
    color: #FF2154;
    font-size: 16px;
    font-weight: bold;
    margin-left: 30px;
}

.employee-note {
    background: #EFF9FF;
    border: 1px solid #277FB4;
    border-radius: 20px;
    padding: 30px 50px;
    margin: 30px 0;
}

.employee-note h4 {
    text-align: center;
    font-size: 18px;
    color: #646464;
}

.employee-note h6 {
    text-align: center;
    font-size: 16px;
    color: #646464;
    margin-bottom: 30px;
}

.employee-note .star-item {
    background: #FFFFFF;
    border: 1px solid #707070;
    padding: 10px 30px;
    border-radius: 20px;
}

.employee-note .star-item p {
    margin-bottom: 5px;
    font-size: 14px;
    color: #646464;
    text-align: center;
}

.willingness-table-container,
.project-table-container {
    margin-top: 50px;
}

.assessment-table-container,
.willingness-table-container,
.project-table-container {
    border: 1px solid #459FCE;
    border-radius: 10px;
}

.assessment-table-container table,
.willingness-table-container table,
.project-table-container table {
    border: 0;
    margin: 0;
}

th,
td,
tr {
    border: 0;
}

.assessment-table-container table thead,
.willingness-table-container table thead,
.project-table-container table thead {
    border-bottom: 1px solid #BCE6FE;
}

.assessment-table-container table th:nth-child(2),
.assessment-table-container table th:nth-child(3),
.assessment-table-container table td:nth-child(2),
.assessment-table-container table td:nth-child(3),
.willingness-table-container table th:nth-child(2),
.willingness-table-container table th:nth-child(3),
.willingness-table-container table td:nth-child(2),
.willingness-table-container table td:nth-child(3),
.project-table-container table th:nth-child(2),
.project-table-container table th:nth-child(3),
.project-table-container table td:nth-child(2),
.project-table-container table td:nth-child(3) {
    border-left: 1px solid #BCE6FE;
    border-right: 1px solid #BCE6FE;
}

.assessment-table-container table thead th,
.willingness-table-container table thead th,
.project-table-container table thead th,
.expected-hike table thead th {
    font-size: 16px;
    font-weight: bold;
    color: #8E8282;
}

.assessment-table-container table tbody tr td,
.willingness-table-container table tbody tr td,
.project-table-container table tbody tr td {
    font-size: 14px;
    font-weight: bold;
    color: #646464;
    vertical-align: middle;
}

.section-title {
    position: relative;
    padding-left: 30px;
}

/* .assessment-table-container table .section-title ,
.willingness-table-container table .section-title ,
.project-table-container table .section-title {
    padding: 30px 40px !important;
} */
.section-title::before {
    position: absolute;
    display: block;
    content: "";
    width: 4px;
    height: 20px;
    background-color: #FF2E5E;
}

.assessment-table-container table th,
.assessment-table-container table td,
.willingness-table-container table th,
.willingness-table-container table td,
.project-table-container table th,
.project-table-container table td,
.expected-hike table th,
.expected-hike table td {
    padding: 10px 40px;
}

.project-table-container .remove img {
    width: 30px;
}

.rating-point {
    font-size: 16px;
    color: #171717;
    background: #C1E7FE;
    padding: 10px;
    border-radius: 15px;
    margin: 0;
    line-height: 1.3;
    text-align: center;
    min-width: 60px;
}

.rating-point.total-point {
    color: #FFFFFF;
    background: #FF2E5E;
}

.relocation p {
    font-size: 20;
    color: #2E2D2D;
}

.relocation {
    border-top: 1px solid #459FCE;
    padding: 70px 40px 30px 40px;
}

.inactive {
    border: 1px solid #A8A8A8;
    border-bottom-width: 5px;
}

.active {
    border: 1px solid #459FCE;
    border-bottom-width: 5px;
}

.relocation-items button {
    font-size: 12px;
    padding: 2px 10px;
    margin: 10px 40px;
    color: #459FCE;
}

.relocation-items .available button {
    border: 1px solid #459FCE;
}

.relocation-items .available button.btn-active {
    color: #FFFFFF;
    background-color: #459FCE;
}

.common-btn {
    font-size: 1rem;
    padding: 10px 30px;
    margin: 10px 40px;
    color: #FFFFFF;
    background-color: #459FCE;
    border: 1px solid #459FCE;
}

.project-table-container {
    background-color: #EFF9FF;
}

.white-bg {
    background-color: #FFFFFF !important;
}

.common-comments,
.expected-hike {
    border: 1px solid #459FCE;
    border-radius: 10px;
    margin-top: 50px;
    overflow: hidden;
}

.common-comments h6 {
    color: #646464;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #459FCE;
    padding: 20px 40px;
}

.common-comments textarea {
    padding: 20px 40px;
    width: 100%;
    border: 0;
}

.top-border {
    border-top: 1px solid #459FCE !important;
}

.bottom-border {
    border-bottom: 1px solid #459FCE !important;
}

.bottom-left {
    border-left: 1px solid #459FCE !important;
}

.bottom-right {
    border-right: 1px solid #459FCE !important;
}

.validation-error {
    float: left;
    font-size: 12px;
    color: red;
    width: 100%;
}

.light-bottom-border {
    border-bottom: 1px solid #DBDBDB;
}

.create-user {
    /* height: 100vh; */
}

.create-user .col-md-6,
.setting-container .col-md-6 {
    padding: 0 10px;
}

.create-user .form-group,
.setting-container .form-group {
    margin-bottom: 30px;
}

.create-user form,
.setting-container form {
    padding: 30px 100px;
    border: 1px solid #ced4da;
    border-radius: 10px;
}

.create-user .col-md-6:nth-child(odd) {
    padding-right: 50px;
}

.create-user .col-md-6:nth-child(even) {
    padding-left: 50px;
}

.create-user .form-group label,
.setting-container .form-group label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.create-user .common-btn,
.setting-container .common-btn {
    margin: 20px auto;
    /* width: 200px; */
}

.login-left {
    height: 80vh;
    padding-top: 20%;
}

.login-left h2 {
    text-align: center;
    text-transform: uppercase;
    line-height: 1.5;
    font-weight: bold;
}

.login-right {
    height: 80vh;
    margin: 0 50px;
    padding-bottom: 20%;
}

.login-right::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background: #707070;
    left: 50%;
    top: 0;
}

.login-right form {
    width: 100%;
}

.login-right .form-group {
    margin-bottom: 20px;
}

.login-right .form-group .form-control {
    border-radius: 20px;
    padding: 7px 20px;
}

.login-form {
    height: 80vh;
    position: relative;
}

.login-container {
    height: 100vh;
}

.login-btn-container {
    height: 20vh;
    position: relative;
}

.login-btn-container button {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: #0599F5;
    border: 0;
    font-size: 26px;
    color: #FFFFFF;
}

.login-btn-container button::after {
    content: "";
    position: absolute;
    width: 110px;
    height: 110px;
    background: white;
    top: 50%;
    border-radius: 55px;
    left: 50%;
    margin-left: -55px;
    margin-top: -55px;
    z-index: -1;
    border: 1px solid #0000001a;
}

.unauthorized {
    height: 100vh;
}

.unauthorized h2 {
    color: #6c6c6c;
    font-size: 20px;
}

.unauthorized a {
    color: #6c6c6c;
    border: 1px solid #6c6c6c;
    padding: 5px 20px;
    margin-top: 10px;
    text-transform: capitalize;
    border-radius: 5px;
}

.user-list-header {
    height: 20vh;
    padding-top: 5%;
}

/* .user-list-container.container h2 {
    color: #0599F5;
    text-align: center;
    margin-top:  20px;
} */
.user-table-container {
    margin-top: 20px;
}

.user-table-container th {
    color: black;
    font-size: large;
}

.user-table-container img {
    width: 20px;
    height: 20px
}

.user-table-container .table-bordered th,
.user-table-container .table-bordered td {
    border: 1px solid #ddd !important
}

.user-table-container button {
    border-width: inherit;
    background-color: white;
    border: 0px
}

.dashboard-header {
    background: #ffffff;
    padding: 30px 0;
    border-bottom: 1px solid #969696;
}

.dashboard-header h2 {
    font-size: 20px;
    /* font-weight: bold; */
    text-transform: uppercase;
    margin: 0 10px;
}

.dashboard-container button {
    border: 1px solid #FF2154;
    background: #FF2154;
    padding: 2px 15px;
    border-radius: 20px;
    color: #FFFFFF;
}

.user-list-container .header-content button,
.setting-container .header-content button,
.create-user .header-content button {
    border: 0;
    border-radius: 50px;
    background: #2e85d5;
    padding: 5px 30px;
    color: #FFFFFF;
}

.user-list-container .header-content .btn-container button:nth-child(odd),
.setting-container .header-content .btn-container button:nth-child(odd),
.create-user .header-content .btn-container button:nth-child(odd) {
    background-color: #FF2154 !important;
    margin-right: 10px;
}

.dashboard-body {
    margin: 30px 0px;
}

.dashboard-item {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 10px;
    background: #60d3b4;
    border-radius: 15px;
    height: 150px;
    position: relative;
    border: 1px solid #4acfab;
    cursor: pointer;
}

.dashboard-item h6 {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
}

.dashboard-item img {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 10px;
    right: 10px;
}

.employee-form-container header {
    position: relative;
}

.employee-form-container header button {
    border: 1px solid #60d3b4;
    background: #60d3b4;
    padding: 2px 15px;
    border-radius: 20px;
    color: #FFFFFF;
}

.employee-form-container header button:nth-child(odd) {
    border: 1px solid #FF2154;
    background: #FF2154;
    margin: 0 10px;
}

.hr-section table,
.print-assessment table {
    border: 1px solid #ddd;
}

.hr-section table td[colspan="5"] {
    text-align: center;
    font-weight: bold;
    padding: 20px 0;
    background: #459FCE;
    font-size: 16px;
    color: #FFFFFF;

}

.hr-section table td[colspan="2"] {
    text-align: center;
    font-weight: bold;
}

.hr-section table td,
.print-assessment table td {
    border: 1px solid #ddd;
}

.print-assessment {
    display: none;
}

.hr-section {
    margin-top: 100px;
}

.print-assessment .primary-bg {
    background-color: #459FCE;
    color: #FFFFFF;
}

@media print {
    .print-assessment {
        display: block;
    }

    .employee-form-container {
        display: none;
    }
}

.overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    z-index: 1010;
    justify-content: center;
    align-items: center;
}

.popup {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    transition: all 5s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: auto;
    margin-left: -200px;
    margin-top: -75px;
}

.popup h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #4acfab;
    ;
    font-family: Tahoma, Arial, sans-serif;
    text-align: center;
}

.popup h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: medium;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
    text-align: center;
}

.popup .close {
    position: absolute;
    top: 15px;
    right: 15px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}

.popup .close:hover {
    cursor: pointer;
    color: #000;
}

.popup .content {
    max-height: 30%;
    overflow: auto;
}

.popup button {
    margin: 0 10px;
}

@media screen and (max-width: 700px) {
    .popup {
        width: 70%;
    }
}

.print-assessment .front-page {
    height: 100vh;
    width: 100%;
    page-break-after: always;
    padding: 80px 40px;
}

.print-assessment .front-page .branding {
    height: 80%;
}

.print-assessment .front-page .branding h2 {
    font-size: 25px;
    margin: 20px 0;
    font-weight: bold;
}

.print-assessment .front-page .branding img {
    width: 300px;
}

.user-details table,
.user-details table tr td {
    border: 0;
}

.user-table-container .copy-icon {
    width: 20px;
    cursor: pointer;
}